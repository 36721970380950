import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.scss';
import { NavLink } from 'react-router-dom';

function Footer(){
    return(
        <section className='FooterSec'>
            <Container>
                <Row>
                    <Col md={10}>
                        <Row>
                            <Col sm={4} md={4} lg={3}>
                                <div className="officeAddressSec">
                                    <img className='footerLogo' src="/images/logo.svg" alt="Edfora" />
                                    <div className="officeAddress">2nd Floor, KH No 274,<br /> Plot No. 5, Saidulajab Extn., Westend Marg,<br /> New Delhi 110030</div>
                                </div>
                            </Col>
                            <Col sm={4} md={4} lg={{span:3, offset:1}} className='footerLinksMobileView'>
                                <h4 className='footerLinkTitle'>Company</h4>
                                <ul className="footerLinks">
                                    <li><NavLink to="/aboutus">About Us</NavLink></li>
                                    <li><NavLink to="/careers">Careers</NavLink></li>
                                    <li><NavLink to="/contact">Contact Us</NavLink></li>
                                    {/* <li><NavLink to="/policyInfo">Policy & Other Info</NavLink></li> */}
                                </ul>
                            </Col>
                            <Col sm={4} md={4} lg={{span:3, offset:1}} className='footerLinksMobileView'>
                                <h4 className='footerLinkTitle'>Legal</h4>
                                <ul className="footerLinks">
                                    <li><NavLink to="/privacypolicy">Privacy Policy</NavLink></li>
                                    <li><NavLink to="/copyright">Copyright</NavLink></li>
                                    <li><NavLink to="/termsservice">Terms of Service</NavLink></li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}>
                        <div className='footerSociaklLinksMobileView'>
                            <div>
                                <h4 className='footerLinkTitle'>Connect with Us</h4>
                                <ul className="footerSociaklLinks">
                                    <li><a className='linkedin' href="https://in.linkedin.com/company/edfora" target='_blank' rel='noopener noreferrer' title='Linkedin'>&nbsp;</a></li>
                                    <li><a className='twitter' href="https://twitter.com/edfora" target='_blank' rel='noopener noreferrer' title='Twitter'>&nbsp;</a></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="copyRightSec text-center">Copyright © Edfora Infotech Pvt. Ltd. 2024. All Rights Reserved</div>
            </Container>
        </section>
    )
}
export default Footer;